<template>
  <div class="col-md-9 mx-auto">
    <!-- HOME -->
    <div class="card rounded shadow">
      <div id="home" class="card-body">
        <div class="row">
          <div class="col-sm-12">
            <div class="row text-center">
              <div class="col-md-12">
                <img src="/images/siged2.png" alt="">
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <h4>Versión: {{ version }}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="row text-center">
              <div class="col-md-12">
                <img v-bind:src="$store.state.logo" style="max-width:100%; height:auto;"/>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12" style="font-size:0.9rem">
                <p><a href="http://www.archivosysistemas.com" target="_blank">Archivos y Sistemas AYS</a> - Copyright {{ new Date().getFullYear() }} - <router-link :to="{ name: 'TerminosYCondiciones' }">Términos y condiciones</router-link></p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row text-center">
              <div class="col-md-12">
                <span style="font-family:arial;font-size:10px;color:#E60D51;font-weight:bold;">Acompañado por:</span>
                <br>
                <img src="/images/LogoAPPSCO.svg" alt="">
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <img src="/images/mintic.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END HOME -->
  </div>
</template>

<script>
  //import moment from 'moment';

  export default {
    components: {

    },
    data() {
      return {
        logo: '',
        uploadPercentage: 0,
        version: null
      }
    },
    beforeCreate(){
      let uri = '/api/version';
      this.axios.post(uri).then((response) => {
        this.version = response.data.version;
      });
    },
    created: function () {

    },
    mounted: function (){

    },
    methods: {

    } // END METHODS
  }
</script>

<style>

</style>
